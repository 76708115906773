import type { NextPage } from 'next';

import { Section } from 'components/layout/Section';
import { Text } from 'components/text/Text';

const NotFound: NextPage = () => (
  <Section
    top={0}
    left={0}
    right={0}
    bottom={0}
    zIndex="z99"
    position="absolute"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    backgroundColor="accent"
  >
    <Text color="white" fontWeight="black" fontSize="hero3">
      404
    </Text>
    <Text marginTop="0.5rem" color="white" fontSize="regular1">
      Page not found
    </Text>
  </Section>
);

export default NotFound;
